import React from "react"

import PageLayout from "components/PageLayout"
import HeroSection from "components/HeroSection"
import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"

import DynamicCards from "components/DynamicCards"
import Accordion from "components/Accordion/Accordion.js"
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CheckIcon from '@material-ui/icons/Check';

import classNames from "classnames"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Quote from "components/Typography/Quote.js"
import LinearProgress from "@material-ui/core/LinearProgress"
import ParallaxSection from "components/ParallaxSection"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"

import aboutUsStyle from "assets/jss/material-kit-pro-react/views/aboutUsStyle.js"
// import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js"

const useStyles = makeStyles(aboutUsStyle)

import OfficeSection from "components/OfficeSection"
import TeamSection from "components/TeamSection"

import { gql, useQuery } from "@apollo/client"

import { Link } from "gatsby"

import remark from "remark"
import remarkHtml from "remark-html"

import SEO from "../components/Seo"

// export const query = graphql`
// {
//   cmsAPI{
//     aboutSingleton {
//       ...SiteTitle
//     }
//   }
// }
// `


export default ({location}) => {
  const classes = useStyles()

  const SERVICES_QUERY = gql`
    {
      services: servicesCollection (filter: { published: true}) {
        title
        description
        image {
          path
          meta
        }
      }
    }
  `

  const { loading, error, data } = useQuery(SERVICES_QUERY)

  if (loading) return <LinearProgress />
  if (error) return `Error! ${error.message}`


  const items = data.services.map((service) => {
    const content = remark()
      .use(remarkHtml)
      .processSync(service.description)
      .toString()

    // return {
    //   // title: service.title,
    //   // content: <GridItem md={12}><p className={classes.description} dangerouslySetInnerHTML={{ __html: content }}></p></GridItem>
      
    // }
    return (
      <List key={service.title}>
      <ListItem md={12}>
        <ListItemIcon>
          <CheckIcon/>
        </ListItemIcon>
        <ListItemText primary={service.title}/>
        </ListItem>
      </List>
    )
  })

  const header_image = "https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"

  return (
    <PageLayout>
      <SEO
          title={"Υπηρεσίες"}
          description={"Οι υπηρεσίες μας"}
          image={header_image}
          pathname={location.pathname}
        />

      <ParallaxSection
        image={header_image}
        filter="dark"
        className={classes.pageHeader}
      />

      {/* <div className={classNames(classes.section, classes.sectionGray)}> */}
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: "2rem" }}>
            <Link color="inherit" to="/">
              Αρχική
            </Link>
            <Typography color="textPrimary">Υπηρεσίες</Typography>
          </Breadcrumbs>
          <GridContainer className={classes.textJustify}>
            <GridItem md={12}>
            {/* <Accordion
              active={0}
              activeColor="rose"
              collapses={items}
            /> */}
            <List>
            {items}
            </List>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* </div> */}
    </PageLayout>
    // </div>
  )
}
